<template>
  <div
    v-if="bannerVisible"
    class="page-bottom-banner"
  >
    <component
      :is="actualBanner"
    />

    <button
      v-if="closeButton"
      class="page-bottom-banner__close"
      @click.prevent="bannerVisible = false"
    >
      <img
        src="/img/intensive/close.svg"
        alt="close"
      >
    </button>
  </div>
</template>

<script>
import BottomBanner from '../v2/global/BottomBanner.vue';

const TIMER_END_DATE = new Date('2025-03-13T00:00:00.000Z');

export default {
  name: 'PageBottomBanner',
  components: {
    BottomBanner,
  },
  props: {
    closeButton: {
      type: Boolean,
      default: true,
    },
  },
  data: () => ({
    bannerVisible: false,

    actualBanner: null,
  }),
  fetch() {
    const now = new Date();

    if (+now < +TIMER_END_DATE) {
      this.bannerVisible = true;
      this.actualBanner = 'BottomBanner';
    }
  },
  computed: {
    // actualDate() {
    //   const now = new Date();
    //   if (+now < +TIMER_END_DATE) {
    //     return TIMER_END_DATE;
    //   }
    //   return TIMER_END_SECOND_DATE;
    // },
    routeName() {
      return this.$route.name;
    },
  },
  watch: {
    routeName(newVal) {
      if (newVal === 'sale') {
        this.bannerVisible = false;
        this.actualBanner = null;
      }
    },
  },
};
</script>

<style scoped lang="scss">

.page-bottom-banner {
  position: fixed;
  bottom: 0;

  width: 100%;

  z-index: 9;

  @include media-down($size-tablet) {
    top: 0;
    bottom: auto;
  }

  .page-bottom-banner__close {
    position: absolute;
    z-index: 3;
    right: calc(var(--scale) * 8);
    top: calc(50% - var(--scale) * 12);
    width: calc(var(--scale) * 24);
    height: calc(var(--scale) * 24);
    padding: 0;
    border: none;
    background-color: transparent;
    cursor: pointer;

    @include media-down($size-tablet) {
      top: 0;
      right: 0;
    }
  }
}

.slide-enter-active {
  -moz-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}
.slide-leave-active {
  -moz-transition-duration: 0.6s;
  -webkit-transition-duration: 0.6s;
  -o-transition-duration: 0.6s;
  transition-duration: 0.6s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}
.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}
.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
